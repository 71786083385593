import originalFetch from 'isomorphic-fetch';
import fetchBuilder from 'fetch-retry';
import { STAGE } from './Dictionary';

const PARTNER_API = process.env.REACT_APP_PARTNER_API;

const writeToCache = (keyName, data) => {
	try {
		sessionStorage?.setItem(keyName, JSON.stringify(data));
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
	}
};

const readFromCache = (keyName) => {
	let item = null;
	try {
		item = JSON.parse(sessionStorage?.getItem(keyName));
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
	}
	return item;
};

/**
 * Use this helper for creating  graphql requests a bit faster.
 * @param  {string} url - the url
 * @param {string} query - the request object for the fetch
 * @param {string} variables: json-encoded string of any vars
 * @returns {Promise} - makes the fetch call and returns the Promise.
 */

function createFetch({
	url,
	method = 'GET',
	body = null,
	retryAttempts = 3,
	retryDelayTime = 1000,
	retryOnCodes = [419, 503, 504],
}) {
	const obj = {
		method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	};

	if (body) {
		obj.body = JSON.stringify(body);
	}

	const options = {
		retries: retryAttempts,
		retryDelay: retryDelayTime,
		retryOn: retryOnCodes,
	};

	const fetch = fetchBuilder(originalFetch, options);

	return fetch(url, obj);
}

export const getION = async ({
	ionID,
	partnerID,
	stage,
}) => {
	if (stage === STAGE.PRODUCTION) {
		const cachedION = readFromCache(ionID);
		if (cachedION) {
			return cachedION;
		}
	}
	const version = 'v3';

	const url = `${PARTNER_API}/${version}/ion/${ionID}/application?partner=${partnerID}`;
	try {
		const res = await createFetch({ url });
		const { data, error } = await res.json();
		if (error) {
			throw new Error(error);
		}
		if (stage === STAGE.PRODUCTION) {
			writeToCache(ionID, data);
		}
		return data;
	} catch (error) {
		return Promise.reject(error); // Sentry is setup to capture this in App.js where this is called.
	}
};
