function getFieldPaths(obj, path = '') {
	if (typeof obj === 'string' && obj.startsWith('$') && obj.includes('.')) {
		return [obj.slice(1)]; // Remove the '$' and add to the result array
	}
	// arrays and objects
	if (typeof obj === 'object') {
		return Object.entries(obj).reduce((result, [key, value]) => {
			const subResult = getFieldPaths(value);
			return result.concat(subResult);
		}, []);
	}

	return [];
}

export default getFieldPaths;
