const ACTION_TYPE = {
	SETUP: 'SETUP',
	UPDATE: 'UPDATE',
};

// TODO: UPDATE STAGES ONCE ENVS ARE SET
const STAGE = {
	LOCAL: 'LOCAL',
	DEVELOPMENT: 'DEVELOPMENT',
	TESTING: 'TESTING',
	STAGING: 'STAGING',
	PRODUCTION: 'PRODUCTION',
};

const APP_STATUS = {
	LOADING: 'LOADING',
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
};

const ACTION_ALIASES = {
	onAddToCart: 'ADD_TO_CART',
	onEndorseSubmit: 'ENDORSE_SUBMIT',
	onEndorseSubmitError: 'ENDORSE_SUBMIT_ERROR',
	onCustomMessage: 'CUSTOM_MESSAGE',
	onRemoveFromCart: 'REMOVE_FROM_CART',
	onUserEvent: 'USER_EVENT',
	overrideScrollToTop: 'SCROLL_TO_TOP',
};

export {
	ACTION_ALIASES,
	ACTION_TYPE,
	APP_STATUS,
	STAGE,
};
