import React, { useRef } from 'react';
import { ulid } from 'ulid';
import { joiResolver } from '@hookform/resolvers/joi';
import { Elements } from '@stripe/react-stripe-js';
import {
	ION_TO_JOI,
	getFields,
	getIonValues,
} from '@buddy-technology/ion-helpers';
import InnerForm from './InnerForm';
import { GlobalStyles } from '../components/UI';
import { Checkout } from '../components';
import { EventProvider, DataProvider, ThemeProvider } from '../context';
import {
	filterConditionalFieldsOrViews,
	prepareION,
	getQuoteRequirements,
} from '../utils';
import logInfo from '../utils/logInfo';
import { VIEW_TYPES } from '../models/dictionary';

const StripedCheckout = ({ stripe, ...rest }) => (
	<Elements stripe={stripe}>
		<Checkout {...rest} />
	</Elements>
);
// todo: JSDOC
const DEFAULTS = {
	VIEW_TYPE: VIEW_TYPES.PAGINATED,
	DATA: {},
	DEBUG: false,
	INCLUDE_CHECKOUT: true,
	LOGO_OVERRIDE: {
		url: 'https://buddy.insure',
		alt: 'Powered by Buddy',
		src: 'https://buddy-img.s3.amazonaws.com/powered-by/powered_by_red.svg',
	},
	STAGE: 'PRODUCTION',
	ON_ADD_TO_CART: () => {},
	ON_REMOVE_FROM_CART: () => {},
	EVENTS_CALL_BACK: (type, data) => {},
	ON_CUSTOM_MESSAGE: () => {},
};

/**
 * Form
 *
 * The main component for rendering Insurance Offering Applications and it's details
 *
 * @param {Object} props
 * @param {Object} props.ion - the parent ion to render
 * @param {string} props.partnerID - ID of the partner affiliating the offering
 * @param {Promise<Token>} [props.stripe] - The returned promise from loadStripe
 * @param {string} [props.viewType='paginated'] - How the form should display itself
 * @param {Object} [props.data={}] - data to pre-populated the form with
 * @param {boolean} [props.includeCheckout=true] - whether or not to display the checkout
 * @param {callback} [props.onAddToCart] - a callback that fires once the form is completed
 * @param {Object} [props.theme] - an object to override the theme settings
 * @param {String} [stage = "staging"] - stage for which endpoints to use: 'development', 'staging', 'production'
 */
const FormComponent = ({
	ion: rawIon,
	partnerID: partnerId,
	stripe,
	viewType = DEFAULTS.VIEW_TYPE,
	data = DEFAULTS.DATA,
	debug = DEFAULTS.DEBUG,
	logoOverride: rawLogoOverride = {}, // default to empty object bc we are spreading default properties into it below
	includeCheckout = DEFAULTS.INCLUDE_CHECKOUT,
	onAddToCart = DEFAULTS.ON_ADD_TO_CART,
	onRemoveFromCart = DEFAULTS.ON_REMOVE_FROM_CART,
	onCustomMessage = DEFAULTS.ON_CUSTOM_MESSAGE,
	theme,
	stage = DEFAULTS.STAGE,
	eventsCallback = DEFAULTS.EVENTS_CALL_BACK,
	placesApiKey,
}) => {
	if (!rawIon) {
		throw new Error('ION is missing.');
	}
	const sessionIdRef = useRef(ulid());

	if (debug) {
		logInfo('ENABLED');
	}

	const logoOverride = { ...DEFAULTS.LOGO_OVERRIDE };
	if (typeof rawLogoOverride === 'object') {
		Object.assign(logoOverride, rawLogoOverride);
	}
	if (!partnerId) {
		// eslint-disable-next-line no-console
		console.error('partner id is required for quoting and purchasing!');
	}

	// this object holds our incoming props in order to run mingo queries where needed.
	const offeringOptions = {
		partnerId,
		viewType,
		data,
		includeCheckout,
	};

	// create ion-derived values.
	const ion = prepareION(rawIon);
	const { views } = ion.application;
	const stepThroughViews = views.filter(({ id }) => id !== VIEW_TYPES.OFFER_ONLY).sort((a, b) => a.order - b.order);
	const checkoutViewInfo = views.find(({ id, type }) => [id, type].includes('checkout'));
	const offerOnlyViewData = views.find(({ id }) => id === VIEW_TYPES.OFFER_ONLY);
	const	quoteFields = getQuoteRequirements(ion);

	// Flatten the Ion's Application to make it easier for RHF and ITR to consume.
	const flatApplication = getFields(ion, true);

	// filtered comes without hidden fields or reference elements
	const filteredApplication = flatApplication.filter(({ uiDisplay }) => (uiDisplay.element !== 'REFERENCE' && uiDisplay.isHidden !== true));

	// Get starting values or defaults to initialize the form.
	const ionValues = getIonValues(flatApplication, data);

	const schema = ION_TO_JOI(filteredApplication);
	const resolver = joiResolver(
		schema,
		{
			allowUnknown: true,
			abortEarly: false,
			errors: {
				wrap: {
					label: '',
					array: '"',
				},
			},
		},
	);

	// check for the first view that's not hidden to set initialView
	const initialView = filterConditionalFieldsOrViews(stepThroughViews, ionValues, ion?.variables, offeringOptions)[0].id;

	return (
		<ThemeProvider theme={theme}>
			<DataProvider
				ion={ion}
				offeringOptions={offeringOptions}
				sessionId={sessionIdRef.current}
				debug={debug}
			>
				<EventProvider
					eventCallback={eventsCallback}
					viewType={viewType}
					partnerId={partnerId}
					onCustomMessage={onCustomMessage}
				>
					<InnerForm
						checkoutViewInfo={checkoutViewInfo}
						includeCheckout={includeCheckout}
						initialView={initialView}
						ion={ion}
						ionValues={ionValues}
						filteredApplication={filteredApplication}
						flatApplication={flatApplication}
						logoOverride={logoOverride}
						offerOnlyViewData={offerOnlyViewData}
						onAddToCart={onAddToCart}
						onRemoveFromCart={onRemoveFromCart}
						partnerId={partnerId}
						quoteFields={quoteFields}
						resolver={resolver}
						stage={stage}
						stepThroughViews={stepThroughViews}
						stripe={stripe}
						StripedCheckout={StripedCheckout}
						views={views}
						viewType={viewType}
						placesApiKey={placesApiKey}
						theme={theme}
					/>
				</EventProvider>
			</DataProvider>
		</ThemeProvider>
	);
};

// TODO: Pull this out into a wrapper component.
// Key prop is set to stringified ION id in case ION is null. This forces the FormComponent to unmount/re-mount on an ION update. This is what we want, otherwise, ION data could persevere in memory (which was happening without this.)
const Form = ({
	theme = { baseTheme: 'base' }, ion, ...rest
}) => (
	<GlobalStyles theme={theme}>
		<FormComponent ion={ion} theme={theme} {...rest} key={`form-${ion.id}`} />
	</GlobalStyles>
);

export default Form;
