import React, { createContext, useContext, useMemo } from 'react';

export const EventContext = createContext();

export const EventProvider = ({
	children,
	eventCallback,
	viewType,
	partnerId,
	onCustomMessage,
}) => {
	const returnValue = useMemo(() => ({
		eventCallback,
		viewType,
		partnerId,
		onCustomMessage,
	}), []);
	return (
		<EventContext.Provider value={returnValue}>
			{children}
		</EventContext.Provider>
	);
};

export const useEventContext = () => {
	const context = useContext(EventContext);
	if (!context) {
		throw new Error('useEventContext must be used within a EventProvider');
	}
	return context;
};
