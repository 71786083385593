import React, { useState, useEffect } from 'react';
import { Numbers } from '@buddy-technology/buddy_helpers';
import { unFlatten } from '@buddy-technology/ion-helpers';
import { useEventContext } from '../context/EventContext';
import { RenderedView, StepThroughNav, IF } from './helpers';
import Markdown from './UI/Markdown';
import Label from './UI/InputLabel';
import Quote from './Quote';

function OfferOnly({
	hideFields,
	viewData,
	quoteViewData,
	quoteRequirements,
	partnerId,
	getValues,
	onAddToCart,
	onRemoveFromCart,
	shouldHideBack,
	stage,
	...sharedProps
}) {
	const premium = getValues('policy::premiumTotal');
	const { eventCallback } = useEventContext();
	const {
		id,
		backButtonLabel,
		options = {},
	} = viewData;

	const {
		navigate,
		control,
		variables,
	} = sharedProps;

	const {
		defaultOfferSelection = 'NOT_SELECTED', // 'OPT_IN' | 'OPT_OUT' | 'NOT_SELECTED'
		element = 'CHECKBOX', // 'CHECKBOX' or 'RADIO'
		isRequired = false, // ignored by 'CHECKBOX'
		label = 'Select Yes or No to continue',
		optInLabel = 'Yes',
		optOutLabel = 'No',
	} = options;

	const [selectedOption, setSelectedOption] = useState(defaultOfferSelection);

	const handleChange = (event) => {
		let checked;
		if (event.target.type === 'checkbox') {
			checked = event.target.checked ? 'OPT_IN' : 'OPT_OUT';
		} else {
			checked = event.target.value;
		}
		setSelectedOption(event.target.value);
		const eventDetails = {
			timestamp: Date.now(),
			viewId: 'offer-only',
			premium: Numbers.toUSD(premium),
			partnerId,
		};
		const callback = checked === 'OPT_IN' ? onAddToCart : onRemoveFromCart;
		const eventType = checked === 'OPT_IN' ? 'onAddToCart' : 'onRemoveFromCart';
		const payload = unFlatten(getValues());
		eventCallback(eventType, eventDetails);
		callback(payload);
	};

	// Automatically trigger addToCart action if defaultValue === 'OPT_IN'
	useEffect(() => {
		if (defaultOfferSelection === 'OPT_IN') {
			const onLoadEventDetails = { target: { value: 'OPT_IN' } };
			handleChange(onLoadEventDetails);
		}
	}, []);

	return (
		<div id="offer-only-container">
			<RenderedView
				getValues={getValues}
				{...viewData}
				{...sharedProps}
				showNav={false}
			/>
			<Quote
				id="quote-view"
				offerOnlyMode
				partnerId={partnerId}
				quoteRequirements={quoteRequirements}
				getValues={getValues}
				stage={stage}
				{...quoteViewData}
				{...sharedProps}
				showNav={false}
			/>
			<IF condition={element === 'CHECKBOX'}>
				<div id="offer-only-input-wrapper">
					<input
						id="offer-only-checkbox"
						className="input form-checkbox"
						defaultChecked={defaultOfferSelection === 'OPT_IN'}
						type="checkbox"
						data-testid="checkbox"
						onChange={handleChange}
						tabIndex="0"
					/>
					<label
						id="offer-only-checkbox-label"
						htmlFor="offer-only-checkbox"
					>
						<Markdown
							getValues={getValues}
							variables={variables}
							text={viewData?.optInLabel || optInLabel}
							index="0"
							viewId="offer-only"
							control={control}
						/>
					</label>
				</div>
			</IF>
			<IF condition={element === 'RADIO'}>
				<div id="offer-only-input-wrapper">
					<Label
						labelFor="offer-only-radiogroup"
						isRequired={isRequired}
						getValues={getValues}
						variables={variables}
						viewId="offer-only"
						control={control}
					>
						{label}
					</Label>
					<div
						data-id="offer-only-yes-container"
					>
						<input
							id="offer-only-yes"
							defaultChecked={defaultOfferSelection === 'OPT_IN'}
							type="radio"
							name="radio"
							value="OPT_IN"
							data-testid="offer-only-yes"
							className={`${selectedOption === 'OPT_IN' ? 'active' : ''}`}
							required={isRequired}
							tabIndex="0"
							onChange={handleChange}
						/>
						<label
							id="offer-only-checkbox-label-yes"
							htmlFor="offer-only-yes"
						>
							<Markdown
								getValues={getValues}
								variables={variables}
								text={optInLabel}
								index="0"
								viewId="offer-only"
								control={control}
							/>
						</label>
					</div>
					<div
						data-id="offer-only-no-container"
					>
						<input
							id="offer-only-no"
							defaultChecked={defaultOfferSelection === 'OPT_OUT'}
							type="radio"
							name="radio"
							value="OPT_OUT"
							data-testid="offer-only-no"
							className={`${selectedOption === 'OPT_OUT' ? 'active' : ''}`}
							required={isRequired}
							tabIndex="0"
							onChange={handleChange}
						/>
						<label
							id="offer-only-checkbox-label-yes"
							htmlFor="offer-only-no"
						>
							<Markdown
								getValues={getValues}
								variables={variables}
								text={optOutLabel}
								index="1"
								viewId="offer-only"
								control={control}
							/>
						</label>
					</div>
				</div>
			</IF>
			<IF condition={!shouldHideBack}>
				<StepThroughNav
					backButtonLabel={backButtonLabel}
					navigate={navigate}
					hideNext
					status={id}
				/>
			</IF>
		</div>
	);
}

export default OfferOnly;
