const base = {
	webFonts: [
		'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
	],
	styles: {
		color: 'var(--color-text-primary)',
		body: {
			fontSize: '16px!important',
			fontFamily: 'Roboto, sans-serif',
			fontWeight: 400,
			color: 'var(--color-text-primary)',
			lineHeight: '1.35!important',
			'@media (min-width: 768px)': {
				fontSize: '18px',
			},
		},
		h1: {
			color: 'var(--color-primary)',
			fontFamily: 'inherit',
			fontWeight: 'bold!important',
			fontSize: '2rem!important',
			lineHeight: '1!important',
			marginTop: '0.5em!important',
			marginBottom: '0.5em!important',
			'@media (min-width: 992px)': {
				lineHeight: '1!important',
				fontSize: '3rem!important',
			},
		},
		h2: {
			color: 'var(--color-text-primary)',
			fontFamily: 'inherit',
			fontWeight: 'bold!important',
			fontSize: '1.8rem!important',
			marginTop: '0.25em!important',
			marginBottom: '0.25em!important',
			lineHeight: '1!important',
			'@media (min-width: 768px)': {
				fontSize: '36px!important',
			},
			'@media (min-width: 992px)': {
				fontSize: '2.5rem!important',
			},
		},
		h3: {
			color: 'var(--color-text-primary)',
			fontFamily: 'inherit',
			fontSize: '1.5rem!important',
			lineHeight: '1.25!important',
			fontWeight: 'bold!important',
			marginTop: '0.25em!important',
			marginBottom: '0.25em!important',
		},
		h4: {
			color: 'var(--color-text-primary)',
			fontFamily: 'inherit',
			fontSize: '0.625rem',
			letterSpacing: '10%',
			textTransform: 'uppercase',
			lineHeight: '1.25',
			fontWeight: '400',
		},
		a: {
			lineHeight: '1.5!important',
			fontWeight: 'bold!important',
			textDecoration: 'none!important',
			fontFamily: 'inherit',
			color: 'var(--color-primary)',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
		strong: {
			fontWeight: 'bold!important',
		},
		p: {
			color: 'var(--color-text-primary)',
			fontFamily: 'inherit',
			fontSize: '1em!important',
			marginTop: '1rem!important',
			marginBottom: '1rem!important',
		},
		'table, ul': {
			marginTop: '1rem!important',
			marginBottom: '1rem!important',
		},
		li: {
			marginTop: '0rem!important',
			marginBottom: '0rem!important',
		},
		'thead > tr > th:first-of-type, tbody > tr > td:first-of-type': {
			paddingLeft: '1rem',
		},
		'thead tr': {
			background: '#ccc',
		},
		'ul:not(.contains-task-list)': {
			listStyleType: 'disc',
		},
		ol: {
			listStyleType: 'decimal',
		},
		blockquote: {
			fontWeight: '500',
			fontStyle: 'italic',
			borderLeftWidth: '0.25rem',
			quotes: '"\\201C""\\201D""\\2018""\\2019"',
			marginTop: '1.6rem',
			marginBottom: '1.6rem',
			paddingLeft: '1rem',
		},
		'#offer-container': {
			maxWidth: '1060px',
		},
		'.card': {
			background: 'var(--background-primary)',
			padding: '2em',
			borderRadius: '10px',
			'@media (min-width: 992px)': {
				padding: '3em',
			},
		},
		'.button-primary': {
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			textTransform: 'capitalize',
			fontSize: '18px',
			lineHeight: 1.5,
			color: '#fff',
			backgroundColor: 'var(--color-primary)',
			borderRadius: '2em',
			borderColor: 'transparent',
			whiteSpace: 'nowrap',
			width: '100%',
			height: '47px',
			'&:hover': {
				backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1) 0 0)',
			},
			'@media (min-width: 768px)': {
				width: 'auto',
				minWidth: 150,
				padding: '0 1em',
			},
		},
		'.button-secondary': {
			lineHeight: 1.5,
			fontWeight: 700,
			color: 'var(--color-secondary)',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
		'.input-text': {
			backgroundColor: '#fff',
			borderStyle: 'none',
			flexGrow: 1,
			fontSize: '18px',
			lineHeight: 1.5,
			color: 'var(--color-text-primary)',
			display: 'block',
			width: '100%',
			padding: '12px 16px 14px',
			paddingBottom: '0.75rem',
			border: '1px solid rgba(0,0,0,.42)',
			borderRadius: '4px',
		},
		'.input-select': {
			background: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOCA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAxLjVMNCA1LjVMNyAxLjUiIHN0cm9rZT0iIzBBMjQyRCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%',
			appearance: 'none',
			// these two props ^ override safari's default styles and sets the dropdown as the background on the select element
			backgroundColor: '#fff',
			borderStyle: 'none',
			flexGrow: 1,
			fontSize: '18px',
			lineHeight: 1.5,
			color: 'var(--color-text-primary)',
			display: 'block',
			width: '100%',
			padding: '12px 16px 14px',
			paddingBottom: '0.75rem',
			border: '1px solid rgba(0,0,0,.42)',
			borderRadius: '4px',
		},
		'.input-label': {
			fontFamily: 'inherit',
			fontSize: '.875rem',
			lineHeight: 1.25,
			color: 'rgba(10, 36, 45, 0.6)',
			display: 'block',
			marginBottom: '6px',
		},
		'.input-invalid': {
			color: '#D62719',
			borderColor: '#D62719',
		},
		'.input-error': {
			lineHeight: 1.5,
			fontSize: 12,
			paddingTop: '6px',
			color: '#D62719',
			fontStyle: 'italics',
		},
		'.bg-primary': {
			background: 'var(--background-primary)',
		},
		'.bg-secondary': {
			background: 'var(--background-secondary)',
		},
		'.progress-bar-label': {
			fontFamily: 'RationalText-Book, sans-serif',
			fontSize: '12px',
			lineHeight: 1.25,
			color: 'rgba(10, 36, 45, 0.6)',
			marginTop: '2rem',
			width: '100%',
		},
		'.progress-bar-container': {
			marginTop: '2.5rem',
			width: '100%',
			background: '#fff',
			borderRadius: '0.5rem',
			height: '0.75rem',
			border: 'solid',
			borderWidth: '0.15px',
			borderColor: 'rgba(10, 36, 45, 0.3)',
		},
		'.progress-bar': {
			backgroundColor: 'var(--color-primary)',
			zIndex: 20,
			height: '100%',
			borderRadius: '0.5rem',
		},
		'#checkout-button-wrapper': {
			marginTop: '2rem',
			width: '100%',
			display: 'flex',
			justifyContent: 'end',
			'@media (min-width: 768px)': {
				width: 'auto',
			},
		},
		'#offer-only-checkbox-label': {
			marginLeft: '1rem',
		},
		'#quote-price-label': {
			fontWeight: 700,
			marginTop: '1rem',
			marginBottom: '1rem',
		},
		'.field-container': {
			marginTop: '1rem',
		},
		'.view-title': {
			marginTop: '1rem',
			fontSize: '1.8rem',
			fontWeight: 'bold!important',
		},
		'#nav-button-container, #checkout-button-wrapper': {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'end',
			alignItems: 'center',
			marginTop: '1rem',
			'@media (min-width: 768px)': {
				flexDirection: 'row-reverse',
			},
		},
		'#back-button, #checkout-back-button': {
			display: 'block',
			marginTop: '1rem',
			'@media (min-width: 768px)': {
				marginRight: 'auto',
				marginTop: 0,
			},
		},
		'.checkbox-wrapper': {
			display: 'flex',
			alignItems: 'center',
		},
		'.radio-button-wrapper': {
			'@media (min-width: 640px)': {
				display: 'flex',
				alignItems: 'center',
			},
		},
		'.radio-button-option-wrapper': {
			marginTop: '1rem',
			display: 'flex',
			alignItems: 'center',
			'@media (min-width: 640px)': {
				marginTop: 0,
				marginRight: '2.5rem',
			},
		},
		'.radio-button-label': {
			marginLeft: '1rem',
			display: 'block',
		},
		'#powered-by-buddy-container': {
			display: 'flex',
			justifyContent: 'end',
			marginTop: '1rem',
		},
		'.single-form-view-container': {
			borderBottomWidth: '1px',
			paddingBottom: '1rem',
		},
		'.single-form-view-container:last-child': {
			borderBottomWidth: '0px',
		},
		'.array-fields-container': {
			display: 'grid',
			gridGap: '10px',
			marginTop: '.75rem',
		},
		'.array-item-pill-container': {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			width: '100%',
			alignItems: 'center',
			padding: 'auto 10px',
			'@media (min-width: 770px)': {
				width: '40%',
				minWidth: '400px',
			},
		},
		'.array-item-pill': {
			border: '1px solid #000',
			padding: '0.75rem',
			borderRadius: '1.5rem',
			gridColumnStart: '1',
			gridColumnEnd: '4',
			gridRow: 1,
			backgroundColor: '#fff',
			color: '#000',
			justifyContent: 'start',
		},
		'.array-item-pill > svg': {
			display: 'inline-block',
		},
		'.array-fields-container > .array-items-add-button': {
			width: '100%',
			'@media (min-width: 770px)': {
				width: '40%',
				minWidth: '400px',
			},
		},
		'.array-item-remove-button': {
			color: 'var(--color-negative)',
			display: 'grid',
			gridColumnStart: 3,
			gridColumnEnd: 4,
			gridRow: 1,
			justifyContent: 'end',
		},
		'.item-pill-edit-button': {
			marginRight: '0.75rem',
			width: '1.25rem',
		},
		'.array-item-remove-icon': {
			width: '1.25rem',
			marginRight: '10px',
		},
		'.array-editor-buttons-container': {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'end',
			marginTop: '1rem',
		},
		'.array-editor-cancel-button': {
			marginRight: '1rem',
		},
		'.array-items-container': {
			marginBottom: '1rem',
			marginTop: '1rem',
		},
		'.array-items-label': {
			fontWeight: 700,
		},
		'array-items-add-button': {
			display: 'flex',
			alignItems: 'center',
		},
		'.array-items-add-icon': {
			marginRight: '0.5rem',
		},
		'#app-editor-container': {
			background: 'var(--background-primary)',
			padding: '2em',
			'@media (min-width: 992px)': {
				padding: '3em',
			},
		},
		'#app-editor-fields-container': {
			paddingTop: '1em',
			paddingBottom: '1em',
		},
		'#see-more-btn': {
			marginTop: '2em',
		},
		'.tooltip': {
			position: 'relative',
			display: 'inline-block',
		},
		'.tooltip-text': {
			whiteSpace: 'break-spaces',
			width: 'max-content',
			maxWidth: '60vw',
			'@media (max-width: 640px)': {
				marginLeft: '-100px',
			},
		},
		'.tooltip .tooltip-text': {
			backgroundColor: '#ccc',
			color: 'var(--color-text-primary)',
			textAlign: 'center',
			padding: '1rem',
			borderRadius: '8px',
			position: 'absolute',
			zIndex: '1',
		},
		'.tooltip:hover .tooltip-text': {
			top: '-5px',
			left: '105%',
		},
		'#suggestions': {
			lineHeight: '1.5rem',
		},
		'#suggestions > *': {
			padding: '.5rem 1rem',
			borderBottom: '1px #333 solid',
		},
		'.stripe-number-el': {
			display: 'block',
			width: '100%',
		},
		'.stripe-expiry-cvc-el': {
			display: 'flex',
			width: '100%',
			marginTop: '1%',
		},
		'.stripe-expiry-el': {
			marginRight: '1%',
		},
	},
};

export default base;
